import { Box, SystemProps, Flex } from "@storyofams/react-ui";
import Image from "next/image";

import { getLinkProps } from "~lib";

import { Button } from "../../Button";
import { Text } from "../../Text";

type ImageBannerProps = {
  first?: boolean;
  content: {
    buttons?: any[];
    title?: string;
    description: string;
    text_align?: string;
    background_image?: {
      filename: string;
    };
    background_image_mobile?: {
      filename: string;
    };
  };
} & SystemProps;

export const TextBanner = ({ content, first, ...props }: ImageBannerProps) => {
  const isFirstVariant = content?.text_align === "start";
  const isOneButton = content?.buttons?.length === 1;
  return (
    <Box
      height={["683px", "716px"]}
      width={"100%"}
      position={"relative"}
      borderRadius={"8px"}
      overflow={"hidden"}
    >
      {content?.background_image?.filename && (
        <>
          <Box
            width={"100%"}
            height={"100%"}
            display={!isFirstVariant ? "block" : ["none", "block"]}
            css={!isFirstVariant && { filter: "brightness(74%)" }}
          >
            <Image
              layout="fill"
              objectFit={"cover"}
              src={content?.background_image?.filename}
            />
          </Box>
          <Box
            width={"100%"}
            height={"100%"}
            display={!isFirstVariant ? "none" : ["block", "none"]}
            css={!isFirstVariant && { filter: "brightness(74%)" }}
          >
            <Image
              layout="fill"
              objectFit={"cover"}
              src={content?.background_image_mobile?.filename}
            />
          </Box>
        </>
      )}
      <Box
        bottom={isFirstVariant && 0}
        top={!isFirstVariant && "50%"}
        transform={!isFirstVariant && "translateY(-50%)"}
        right={!isFirstVariant && 0}
        left={!isFirstVariant && 0}
        mx={!isFirstVariant ? "auto" : "auto"}
        p={isFirstVariant ? ["20px", "37px"] : [3, 0]}
        css={isFirstVariant && { "backdrop-filter": "blur(17.8px)" }}
        borderTopRightRadius={isFirstVariant && ["none", "8px"]}
        maxWidth={isFirstVariant ? "620px" : "940px"}
        boxShadow={isFirstVariant && "6px -5px 27.7px 0px #0000002E;"}
        position={"absolute"}
        width={["100%", "max-content"]}
      >
        <Text
          mb={isFirstVariant ? ["15px", 3] : 3}
          fontWeight={700}
          fontSize={isFirstVariant ? ["36px", "56px"] : ["40px", "56px"]}
          lineHeight={
            isFirstVariant ? ["43.2px", "67.2px"] : ["48px", "67.2px"]
          }
          fontFamily={"DINPro"}
          textAlign={isFirstVariant ? ["center", "start"] : "center"}
          color={!isFirstVariant ? "white" : "#1D1D1B"}
        >
          {content?.title}
        </Text>
        <Text
          mb={isFirstVariant ? ["15px", 3] : 3}
          color={!isFirstVariant ? "white" : "#1D1D1B"}
          fontSize={["18px", "24px"]}
          textAlign={isFirstVariant ? ["center", "start"] : "center"}
          lineHeight={["25.2px", "33.6px"]}
        >
          {content?.description}
        </Text>

        <Flex justifyContent={"center"} flexDirection={["column", "row"]}>
          {content?.buttons?.map(({ colour, label, url }, i) => (
            <Button
              key={i}
              height={"53px !important"}
              mr={!isOneButton && !i && [0, 2]}
              minWidth={isFirstVariant ? "265px" : "316px"}
              mb={!i && 1}
              width={isFirstVariant && isOneButton && "100%"}
              variant={colour}
              bg={colour === "primary" ? "black" : "white"}
              color={colour === "primary" ? "white" : "black"}
              href={getLinkProps(url)}
            >
              {label}
            </Button>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};
